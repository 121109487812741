// SLIDER

// this function is also run by ReelDeck.vanilla.js and needs to be global scope
// use 'var' instead of 'let' here because of storybook
var slider = () => {
  const sliders = document.querySelectorAll('.tc_slider:not([data-processed="js"])');

  // loop through all non-processed sliders on page
  sliders &&
    sliders.forEach((slider) => {
      const sliderList = slider.querySelector('.tc_slider__list');
      const sliderItems = sliderList.querySelectorAll('.tc_slider__list > li');
      const buttonPrev = slider.querySelector('.tc_slider__button--prev');
      const buttonNext = slider.querySelector('.tc_slider__button--next');
      const hasButtons = buttonPrev || buttonNext;
      let currentSlide = sliderList.querySelector('[aria-current="true"]');
      currentSlide = currentSlide && currentSlide.tagName === 'A' ? currentSlide.parentNode : currentSlide;
      const buffer = sliderItems && sliderItems[0].clientWidth * 0.5; // half the width of an item

      let resizeTimer = null;
      let windowWidth = window.innerWidth;
      let sliderWidth = sliderList.clientWidth;

      // scroll to current slide
      const scrollToCurrentSlide = () => {
        if (currentSlide) {
          const currentSlideRect = currentSlide.getBoundingClientRect();
          const currentSlideWidth = currentSlideRect.width;
          const currentSlideOffset = currentSlide.offsetLeft;
          const scrollWidth =
            currentSlideOffset + currentSlideWidth / 2 - sliderWidth / 2;

          currentSlideOffset + currentSlideWidth > sliderWidth &&
            sliderList.scrollTo(scrollWidth, 0);
        }
      };

      // enable/disable buttons
      const enableButtons = () => {
        let prevDisabled = sliderList.scrollLeft < buffer;
        let nextDisabled =
          sliderList.scrollLeft > sliderList.scrollWidth - sliderWidth - buffer;

        if (prevDisabled) {
          buttonPrev.setAttribute('disabled', 'disabled');
        } else {
          buttonPrev.removeAttribute('disabled');
        }

        if (nextDisabled) {
          buttonNext.setAttribute('disabled', 'disabled');
        } else {
          buttonNext.removeAttribute('disabled');
        }
      };

      // do the actual sliding
      const handleSlide = (e) => {
        const prev = e.target.closest('.tc_slider__button--prev');
        const next = e.target.closest('.tc_slider__button--next');

        let distance = 0;
        if (prev) {
          distance = -sliderWidth + buffer;
        }
        if (next) {
          distance = sliderWidth - buffer;
        }
        slider.classList.add('js_slider--snap');
        sliderList.scrollBy({ left: distance, behavior: 'smooth' });
        slider.classList.remove('js_slider--snap');
      };

      // initialize slider
      const initSlider = () => {
        if (sliderItems) {
          // scroll to current slide on load - if it exist and it's out of view
          scrollToCurrentSlide();

          if (hasButtons) {
            slider.addEventListener('click', handleSlide);
            enableButtons();
          }
        }
      };

      // in case the page gets resized
      window.addEventListener('resize', () => {
        if (Math.abs(windowWidth - window.innerWidth) < 5) return;
        clearTimeout(resizeTimer);
        windowWidth = window.innerWidth;

        resizeTimer = setTimeout(() => {
          if (hasButtons) {
            slider.removeEventListener('click', handleSlide);
          }
          sliderWidth = sliderList.clientWidth;
          initSlider();
        }, 300);
      });

      // monitor if buttons should change visibility
      if (hasButtons) {
        sliderList.addEventListener('scroll', () => {
          enableButtons();
        });
      }

      // run the whole thing
      initSlider();

      // add 'processed' attribute to prevent script running more than once
      slider.setAttribute('data-processed', 'js');
    });
};

slider();
